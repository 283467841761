import React, { Children, forwardRef, FunctionComponent, useEffect, useImperativeHandle, useState } from "react";
import styles from "./operations.module.scss";
import stylesApp from '../../globalStyles.module.scss';
import { inherits } from "util";
import { Settings } from "../../helpers/Settings";
import { ArrowLeft, ArrowRight, CloseModal, Coin, Cost, Gain, Household, MiniPickup, RecyclingCenter } from "../../components/icons/icons";

interface OperationsProps {
    title?: string;
    fontFamily?: string;
    fontSize?: string;
    onClick?: (value: any) => void;
    onChange?: (value: any) => void;
    onClose?: () => void
    children?: React.ReactNode;
}

export interface OperationsCommunicators {
    onUnload: () => void;
}

const Operations: FunctionComponent<OperationsProps> = (props) => {

    //const [containerStyle, setContainerStyle] = useState<string>(styles.modalContainer);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {props.title!}
                </div>
                <div className={styles.close}>
                    <CloseModal onClick={() => {
                        if (props.onClose!)
                            props.onClose();
                    }} />
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.source}>
                    <Household />
                </div>
                <div className={stylesApp.verticalLink} style={{ height: '60px' }}></div>
                <div className={styles.cardArea}>
                    <ArrowLeft />
                    <div className={styles.cardWrapper}>
                        <div className={styles.card}>
                            <div className={styles.agentFrame}>
                                <div className={styles.top}>
                                    <div className={styles.iconSection}>
                                        <div className={styles.iconTitle}>
                                            mini pickup
                                        </div>
                                        <div className={styles.icon}>
                                            <MiniPickup />
                                        </div>
                                    </div>
                                    <div className={stylesApp.verticalLink} style={{ height: '90px', marginTop: '1em' }}>
                                    </div>

                                    <div className={styles.priceSection}>
                                        <div className={styles.separator}></div>
                                        <div className={styles.pricesArea}>
                                            <div className={styles.prices}>
                                                <div className={styles.price}>
                                                    <div className={styles.direction}>
                                                        <Cost />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <div className={styles.amount}>
                                                            123
                                                        </div>
                                                        <div className={styles.coin}>
                                                            <Coin />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.divider}></div>
                                                <div className={styles.price}>
                                                    <div className={styles.direction}>
                                                        <Gain />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <div className={styles.amount}>
                                                            345
                                                        </div>
                                                        <div className={styles.coin}>
                                                            <Coin />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.description}>
                                    you get 20 coins / pallet
                                </div>
                            </div>
                        </div>

                        <div className={styles.card}>
                            <div className={styles.agentFrame}>
                                <div className={styles.top}>
                                    <div className={styles.iconSection}>
                                        <div className={styles.iconTitle}>
                                            mini pickup
                                        </div>
                                        <div className={styles.icon}>
                                            <MiniPickup />
                                        </div>
                                    </div>
                                    <div className={stylesApp.verticalLink} style={{ height: '90px', marginTop: '1em' }}>
                                    </div>

                                    <div className={styles.priceSection}>
                                        <div className={styles.separator}></div>
                                        <div className={styles.pricesArea}>
                                            <div className={styles.prices}>
                                                <div className={styles.price}>
                                                    <div className={styles.direction}>
                                                        <Cost />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <div className={styles.amount}>
                                                            123
                                                        </div>
                                                        <div className={styles.coin}>
                                                            <Coin />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.divider}></div>
                                                <div className={styles.price}>
                                                    <div className={styles.direction}>
                                                        <Gain />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <div className={styles.amount}>
                                                            345
                                                        </div>
                                                        <div className={styles.coin}>
                                                            <Coin />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.description}>
                                    you get 20 coins / pallet
                                </div>
                            </div>
                        </div>
                    </div>
                    <ArrowRight />
                </div>
                <div className={stylesApp.verticalLink} style={{ height: '100px' }}></div>
                <div className={styles.destination}>
                    <div className={styles.icon}>
                        <RecyclingCenter />
                    </div>
                    <div className={styles.title}>
                        local storage
                    </div>
                </div>
            </div>
            <div className={stylesApp.separator}></div>
        </div>
        // <div className={`${containerStyle} ${animation}`}
        //     style={{
        //         fontFamily: props.fontFamily!, fontSize: props.fontSize!,
        //         width: props.width!,
        //         height: props.height!,
        //         backgroundColor: props.glassEffect ? 'unset' : (!props.disabled ? props.color! : props.disabledColor!),
        //         borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
        //         opacity: props.opacity,
        //         zIndex: props.zIndex ? props.zIndex! : '10000',
        //     }}
        //     onClick={() => {
        //         if (!props.disabled && props.onClick)
        //             props.onClick(false);
        //     }}>
        //     {props.children}
        // </div>
    );
}

export default Operations;