import { FunctionComponent, useEffect, useRef, useState } from "react";
import { GlobalContext } from "./GlobalContext";
import AlertPopup from "../components/alertPopup/AlertPopup";
import ModalDialog from "../components/ModalDialog/ModalDialog";
import Busy from "../components/loading/Busy";
//import { getNotifications } from "../restAPI/notifications";

const GlobalWrapper: FunctionComponent<any> = (props) => {

    const [user, setUser] = useState<any|undefined>();
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
    
    const [notifications, setNotifications] = useState<any>();
    
    useEffect(() => {
        let intervalNotifications:any;
        (async () => {
            const userAccount = localStorage.getItem('activeAccount');
            const accountData = await JSON.parse(userAccount!);
            if(accountData!){
                setUser(accountData?? undefined);
                notificationLoader(accountData!.pkUser, accountData!.type);
                intervalNotifications = setInterval(notificationLoader
                //     async () => {
                //     console.log(`interval ${intervalNotifications} + ` + Date());
                //     setNotifications(await getNotifications(accountData!.pkUser, accountData!.accountType));
                // }
                , 30000, accountData!.pkUser, accountData!.type);
            }
            else
                setUser(undefined);
        })()
        return () => {
            clearInterval(intervalNotifications);
        }
    }, []);

    const notificationLoader = async(userId:string, type:string) => {}
    ToDO: //bring back notifications code into the notificationLoader function 
    // console.log(userId, type);
    //     //if(user!)
    //          //remove remarks setNotifications(await getNotifications(userId, type));
    // }

    return (
        <GlobalContext.Provider value={{
            //user authentication
            user,
            displayingCategory: selectedCategory,
            updateCategory: (newValue: string) => {
                setSelectedCategory(newValue);
            },
            notifications
        }}>
            
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalWrapper;