import {createContext, useState} from "react";

interface GlobalContextProps {
    //user authentication
    user: any|undefined,
    displayingCategory: string|undefined,
    updateCategory:(newCategory:string) => void,

    notifications: any|undefined,
}

export const GlobalContext = createContext<GlobalContextProps>({
    //user authentication
    user: undefined,
    displayingCategory:undefined,
    updateCategory: (value:string)=>{},

    notifications: undefined,
});