import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import styles from './App.module.scss';
import { initiateUser, UserData } from './restAPI/userGate';
import { getCurrent } from './restAPI/stockGate';
import { Bottle, Camion, CloseModal, Coin, Folder, Menu, MenuTapped, MiniPickup, Oxygen, Truck, Warehouse } from './components/icons/icons';
import { PopupsContext } from './handlers/PopupsContext';
import GlobalWrapper from './handlers/GlobalWrapper';
import PopupsWrapper from './handlers/PopupsWrapper';
import Modal, { ModalCommunicators } from './components/Modal/Modal';
import Operations from './pages/Operations/Operations';

const bottleWeightNormal = 18.5;
const surfacePollutionLevel = 80;
const oxygenLevel = 66;

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        initData: string;
        initDataUnsafe: {
          user: {
            id: number;
            first_name: string;
            last_name: string;
            username: string;
            language_code: string;
          };
        };
        expand: () => void;
        sendData: (data: string) => void;
      };
    };
  }
}

function App() {

  const globalTime = useRef<NodeJS.Timer>();
  const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);

  const modalsRef = useRef<ModalCommunicators>(null);
  const [collectedPlastics, setCollectedPlastics] = useState<number>(0);
  const [displayWeight, setDisplayWeight] = useState<number>(2000);
  const [collectedWorth, setcollectedWorth] = useState<number>(0);
  const [menuTapped, setMenuTapped] = useState<boolean>(false);
  const [localStorage, setLocalStorage] = useState<boolean>(false);
  //const [collectedWorthDisplayable, setcollectedWorthDisplayable] = useState<string>('0');

  //#region Telegram userData
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = useState('');
  const [currentUser, setCurrentUser] = useState<UserData>();
  const [initResult, setInitResult] = useState('no response ...');
  //#endregion

  useEffect(() => {
    (async () => {

      //#region Linking Telegram account
      // Check if the Telegram WebApp object exists (it will be available only when opened in the Telegram app)
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        // Extract user data from the WebApp initDataUnsafe object
        const user = tg.initDataUnsafe?.user;
        if (user) {
          // setUsername(user.username);
          // setFirstName(user.first_name);
          // setLastName(user.last_name);
          // setUserId(user.id.toString());
          setCurrentUser({
            telegramId: user.id.toString(),
            name: user.first_name,
            surname: user.last_name,
            username: user.username
          })
        }

        // Expand the WebApp to fill the entire screen
        tg.expand();
      }
      //#endregion Linking Telegram account
      // setCurrentUser({
      //   telegramId: '1234',
      //   name: 'Alex',
      //   surname: 'Pi',
      //   username: 'API'
      // });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (currentUser!) {
        const initiationResult = await initiateUser(currentUser!);

        if (initiationResult!) {
          if (initiationResult.pkUser) {
            const stockResult = await getCurrent(initiationResult.pkUser);
          }
          setInitResult(initiationResult.result);
        }
        else
          setInitResult('no RESULT');
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
    })();
  }, [menuTapped]);

  useEffect(() => {
    const weight = bottleWeightNormal * collectedPlastics / 1000;
    setDisplayWeight(weight);
    setcollectedWorth(weight * 250 / 1000);
    globalTime.current = setInterval(() => {
      // ...
    }, 1000);
    //setcollectedWorthDisplayable(toWorthString(weight * 250 / 1000));    
    //}, [collectedPlastics, currentUser]);//username, firstName, lastName, userId]);
  }, [collectedPlastics, username, firstName, lastName, userId, initResult]);

  const pickPlastic = () => {
    setCollectedPlastics(collectedPlastics + 1);
  }

  const toWeightString = (weight: number) => {
    const result = weight.toString();
    if (weight < 1)
      return result.substring(0, 4);
    else if (weight < 1000)
      return Math.floor(weight).toString();
    else if (1000 <= weight && weight < 1000000)
      return Math.floor(weight / 1000) + 'K';
    else if (1000000 <= weight && weight < 1000000000)
      return Math.floor(weight / 1000000) + 'M';
    else if (1000000000 <= weight && weight < 1000000000000)
      return Math.floor(weight / 1000000000) + 'B';
    return result;
  }

  const toWorthString = (worth: number) => {
    const result = worth.toString();
    if (worth < 1)
      return result.substring(0, 4);
    else if (worth < 1000)
      return Math.floor(worth).toString();
    else if (1000 <= worth && worth < 1000000)
      return Math.floor(worth / 1000) + 'K';
    else if (1000000 <= worth && worth < 1000000000)
      return Math.floor(worth / 1000000) + 'M';
    else if (1000000000 <= worth && worth < 1000000000000)
      return Math.floor(worth / 1000000000) + 'B';
    return result;
  }

  return (
    <div className={styles.container} onClick={() => pickPlastic()}>
      {localStorage &&
        <Modal glassEffect='CLEARGLASS' ref={modalsRef} onClose={() => {setLocalStorage(false)}}>
          <Operations title={'transfer for stock'} onClose={() => {
            if (modalsRef.current) {
              modalsRef.current.onUnload();
            }
          }}></Operations>
        </Modal>
      }
      <div className={menuTapped ? (`${styles.menu} ${styles.menuHover}`) : styles.menu}>
        {menuTapped ? <MenuTapped className={styles.menuIcon} onClick={() => { setMenuTapped(!menuTapped); }} /> : <Menu className={styles.menuIcon} onClick={() => { setMenuTapped(!menuTapped); }} />}
        {menuTapped &&
          <>
            <div className={`${styles.menuItem} ${styles.warehouse}`}>
              <Warehouse className={styles.itemIcon}
                onClick={() => {
                  setLocalStorage(true);
                }}
              />
            </div>
            <div className={`${styles.menuItem} ${styles.miniPickup}`}>
              <MiniPickup className={styles.itemIcon} />
            </div>
            <div className={`${styles.menuItem} ${styles.camion}`}>
              <Camion className={styles.itemIcon} />
            </div>
            <div className={`${styles.menuItem} ${styles.truck}`}>
              <Truck className={styles.itemIcon} />
            </div>
          </>
        }
      </div>
      <div className={styles.menuClickBackground}></div>


      <div className={styles.globalLight}>
      </div>

      <div className={styles.globalFloor}>
        {/* <img src={require('./assets/floor.png')} height='100%' width='100%' style={{ opacity: '1' }} /> */}
      </div>

      #region dynamic Algaes
      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '10px', left: '15%' }}>
        <img src={require('./assets/Algae.png')} height='100%' style={{ opacity: '0.8' }} />
      </div>

      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '10px', left: '50%' }}>
        <img src={require('./assets/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>

      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '10px', right: '10%' }}>
        <img src={require('./assets/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>
      #endregion

      #region static Algaes
      <div className={styles.algae} style={{ zIndex: '2', bottom: '0px', left: '0px' }}>
        <img src={require('./assets/Algae0130.png')} height='80%' style={{ opacity: '1' }} />
      </div>

      <div className={styles.algae} style={{ zIndex: '1', bottom: '0px', left: '25%' }}>
        <img src={require('./assets/Algae0090.png')} height='100%' style={{ opacity: '1' }} />
      </div>

      <div className={styles.algae} style={{ zIndex: '1', bottom: '0px', right: '25%' }}>
        <img src={require('./assets/Algae0000.png')} height='100%' style={{ opacity: '1' }} />
      </div>
      #endregion

      #region bubbles
      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '10%', left: '0px' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '35%', left: '25%' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '20%', left: '70%' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>
      #endregion

      #region user worth
      <div className={styles.collectionDataContainer}>
        <div className={styles.currentBottles}>
          <div className={styles.text}>
            {displayWeight < 1 ? displayWeight.toString().substring(0, 4) : (displayWeight < 1000 ? Math.floor(displayWeight) : Math.floor(displayWeight / 1000))} {displayWeight < 1000 ? 'Kg' : 'T'}
          </div>
        </div>
        <div className={styles.currentWorth}>
          <div className={styles.text}>
            {toWorthString(collectedWorth)}
          </div>
        </div>
        <Coin className={styles.coinIcon} />
      </div>
      #endregion

      #region environment statistics
      <div className={`${styles.guageContainer} ${styles.o2Container}`}>
        <div className={`${styles.guageCylinder} ${styles.o2Cylinder}`} style={{ height: '100px' }}>
        </div>
        <div className={styles.guageDataContainer}>
          <div className={styles.guageData}>
            {`Oxygen ${oxygenLevel}%`}
          </div>
        </div>
        <Oxygen className={styles.guageIcon} />
      </div>
      <div className={`${styles.guageContainer} ${styles.pollutionContainer}`}>
        <div className={`${styles.guageCylinder} ${styles.pollutionCylinder}`} style={{ height: '90%' }}>
        </div>
        <div className={styles.guageDataContainer}>
          <div className={styles.guageData}>
            {`Pollution ${surfacePollutionLevel}%`}
          </div>
        </div>
        <Bottle className={styles.guageIcon} />
      </div>
      #endregion

      <div className={styles.folder}>
        <Folder />
        <div className={styles.inhouse}>3</div>
      </div>
    </div>
  );
}

export default App;
