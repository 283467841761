export const Settings = {
  api: {
    path: process.env.NODE_ENV === 'production' ? 'https://plato.sustainablife.online' : 'http://emerald:8080',
    authenticationToken: 'plato!inOcean24'
  },

  tempCategories: [
    { 'label': 'plastics (mix.)', 'value': '0' },
  ],

  inAppVars: {
    modalDialogLoadTime: 500,
    modalDialogUnloadTime: 500,
  }

}